@import url('../Fonts/Inter.css');

$small: 430px;
$medium: 1100px;

/**
* Add Interaction CSS
*/
.mb-zero {
  margin-bottom: 0 !important;
}
input::placeholder,
.select-placeholder-text,
.css-1jqq78o-placeholder {
  color: #929292;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.00088rem;
}
.dropzone-product {
  cursor: pointer;
}
.hr-formC {
  border-top: 1px solid #f3f3f3 !important;
  margin: 0;
}
// .padding-right {
//   // padding-left: 0;
// }
.cross-icon-cart {
  border-radius: 0.25rem;
  background: #f2f2f2;
  display: flex;
  width: 2rem;
  height: 2rem;
  padding: 0.25rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.custom-height-select {
  height: 2.345rem;
}
.CreatableSelect-input input {
  width: 100px !important;
}
.css-f4b163-option:active {
  background-color: #753cb7 !important;
  color: white;
}
.css-t3ipsp-control {
  box-shadow: 0 0 0 0 #753cb7 !important;
  border-color: #753cb7 !important;
  border: 1px solid #753cb7 !important;
  border-radius: 4px;
}
.ck-focused {
  border-color: #753cb7 !important;
}

.Custom-input {
  accent-color: #753cb7 !important;
}
.cross-icon-cart:hover {
  border-radius: 0.25rem;
  background: #dadada;
}
.add-intaraction-css {
  & .page-no-counter {
    margin: 0px auto !important;
  }
  & .main-pagination-div {
    width: 5.5rem;

    & .arrow-btn-v2 {
      cursor: pointer;
      border-radius: 4px;
      border: 1px solid var(--stroke, #e6e6e6);
      background: #fff;
      height: 2rem;
    }
    & .number-input {
      border-radius: 4px;
      border: 1px solid var(--stroke, #e6e6e6);
      background: #fff;
      height: 2rem;
      width: 5.5rem;
    }
    & .number-input::placeholder {
      color: #1e293b;
      font-family: Inter;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

// Add.Edit button

.addeditbutton-css {
  & .paginationmaindiv {
    & .arrow-btn-v2 {
      cursor: pointer;
      border-radius: 4px;
      border: 1px solid var(--stroke, #e6e6e6);
      background: #fff;
      height: 2rem;
    }
    & .number-input {
      text-align: center;
      border-radius: 4px;
      border: 1px solid var(--stroke, #e6e6e6);
      background: #fff;
      height: 2rem;
      width: 5.5rem;
    }
    & .number-input::placeholder {
      justify-content: center;
      display: flex;
      color: #1e293b;
      font-family: Inter;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

.custom-heading-position .custom-label {
  margin-left: -1rem;
  color: var(--text-box-Stroke, #929292);
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.w-100-div .Select-input {
  width: 100% !important;
}
.bottom-button {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

// Options Css
.options-main-div {
  & .selectratio-height {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1e293b;
  }
  & .selectratio-height:hover {
    background: #ffffff;
    border: 1px solid rgba(33, 207, 214, 0.35);
    border-radius: 4px;
  }
  & .ActiveButton {
    background: #ffffff;
    border: 1px solid #21cfd6;
    border-radius: 4px;
  }
  & .Interactionpropertymaindiv {
    min-height: 23rem !important;
    & .divinline-flex {
      // height: 500px !important;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
  & .Previewbutton {
    display: inline-flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 0.5rem;
  }
}
.Previewbutton {
  display: inline-flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.5rem;
}
.width-48 {
  padding: 0;
  display: flex;
  width: 11rem;
  justify-content: center;
  align-items: center;
}

// Video interration

.radio-box-videolink {
  height: 3rem;
  padding: 0.75rem 1.5rem 0.75rem 1.5rem;
}
.collateral-video {
  width: 18rem !important;
  & p,
  span {
    font-size: 14px;
  }
}

// Menu interaction
.Tree-model {
  width: 100%;
}

.nobackground {
  background: none !important;
  box-shadow: none !important;
}
// .menu-handler-v2 {
//   // max-height: none !important;
//   // height: 100% !important;
// }
.form-height-v2 {
  height: 91% !important;
}
.bottonbutton {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 38rem;
}
.display-none {
  display: none !important;
}

/** End */

// Dropdoen hover
.hoverstyle {
  & svg {
    fill: #929292;
  }
}
.hoverstyle:hover {
  border-radius: 0.25rem;
  border: 1px dashed var(--1, #753cb7);
  background: #f9f6fc;
  & svg {
    fill: #753cb7;
  }
}

/**====================================For options Css===========================================*/

.tippy-box[data-theme~='dark'] {
  border-radius: 4px;
  background: #f2f2f2;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.spot-option .spot-opt {
  border: 0px solid !important;
}

.centerproductbutton {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.moreinfobutton {
  display: flex;
  padding: 0.5rem 1.375rem 0.5rem 1.375rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0.5rem;
  border: 1px solid var(--border, #e6e6e6);
  background: #fdfdfd;
  color: #565656;
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

// =========================================End===================================================
/**====================================For Shimmer Css===========================================*/

@keyframes shimmering {
  from {
    transform: translateX(-100%) skewX(-45deg);
  }

  to {
    transform: translateX(100%) skewX(-45deg);
  }
}

.shimmer {
  position: relative;
  display: inline-block;
  margin-right: 4px;
  background-color: lightgray;
  color: transparent;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
}

.shimmer::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 200%;
  height: 100%;
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
  animation: shimmering 1.2s linear 0 infinite both;
}
.Overflow-body {
  max-height: 47rem;
  overflow: auto;
}

// =========================================End===================================================

.navbar {
  padding: 0.5rem 1rem !important;
}
.container-define {
  & .container-fluid {
    padding: 0 !important;
    height: 100% !important;
  }
}

// =========================================Bootstrap Changes===================================================

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
.tab-Select {
  & hr {
    background-color: #753cb7 !important;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
}
hr {
  border: 0;
  opacity: 1;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  background-color: unset !important;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
.page-item {
  & a {
    color: #753cb7 !important;
  }
}
.selected {
  & a {
    background-color: #753cb7 !important;
    color: white !important;
  }
}
.User-permistion > * {
  flex-shrink: 0;
  width: 22%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.login_container_fe94 {
  max-width: 100% !important;
  height: calc(100vh - 6px);
  padding: 0;
  & .logintoplogo {
    position: absolute;
    left: 0;
    padding: 2rem;
    z-index: 1;
  }
  @media screen and (max-width: $small) {
    & .logintoplogo {
      position: unset;
    }
  }
  @media screen and (min-width: $medium) {
    //do Smth
  }
  & .gap-between {
    display: flex;
    height: 100%;
    // gap: 9%;
    @media screen and (max-width: $medium) {
      display: block;
    }
  }
  & .login_video {
    width: 55%;
    padding: 1rem;
    clip-path: inset(1px 1px);
    @media screen and (max-width: $small) {
      display: none;
    }
    @media screen and (max-width: $medium) {
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
    }
    & video {
      clip-path: inset(1px 1px);
      border: none;
      border-radius: 10px;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  & .login_form {
    width: 45%;
    @media screen and (max-width: $medium) {
      width: 100%;
    }
  }
}

// ================================================End==========================================================

// ============================================Add interraction=================================================
.bgVideoPlayer {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
// ================================================End==========================================================
@media screen and (max-width: $small) {
  .fullHeightLogin {
    min-height: calc(100vh - 100px);
  }
  .login_container_fe94 .gap-between {
    height: min-content;
  }
}

// ============================================Collateral Overlay=================================================

.thumb-overlay {
  bottom: 0;
  display: inline-block;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  transition: bottom 0.4s ease;
  width: 100%;
  & .overlay-bg {
    background: #999696e3;
    // background: var(--theme-color);
    bottom: 0;
    height: 100%;
    left: 0;
    opacity: 0.65;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 1;
  }
  & .spinner {
    color: #753cb7 !important;
    position: relative;
    z-index: 3;
    width: 3rem;
    height: 3rem;
  }
  & .Overlayloaderdiv {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
    & span {
      font-size: 1.4rem;
      font-weight: 500;
      font-family: inter;
    }
  }
}

// ================================================End==========================================================

// ================================================Float tag==========================================================

/* common */
.ribbon {
  width: 84px;
  z-index: 5;
  height: 5.75rem;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  // border: 3px solid #9747ff; /* Adjusted border thickness */
}
.ribbon span {
  color: #fafafa;
  text-align: center;
  font-family: Inter;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  display: block;
  width: 150px; /* Adjusted width */
  padding: 10px 0; /* Adjusted padding */
  background-color: #9747ff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1); /* Adjusted box shadow */
  color: #fff;
  font:
    700 12px/1 'Lato',
    sans-serif; /* Adjusted font size */
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
}
/* top left*/
.ribbon-top-left {
  top: 0;
  left: 0;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-top-left::before {
  top: 0;
  right: 0;
}
.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}
.ribbon-top-left span {
  right: -18px;
  top: 10px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.formC-height {
  height: calc(100% - 60px);
}

// ================================================End==========================================================
.Regiondiv {
  width: 46.5rem;
  & .modal-header {
    border-bottom: 0;
  }
  & .modelbody {
    padding: 0rem 4rem;
    & .checkinput {
      padding-bottom: 1rem;
    }
  }
  & .radio-box-setting {
    width: 50%;
  }
}

// ================================================== View Proposal Status ===========================================

.Converted-style {
  border-radius: 21.375rem !important;
  border: 1px solid var(--green, #45a076) !important;
  background: #e8f5ef !important;
}
.Pending-style {
  border-radius: 3.5rem !important;
  border: 1px solid #edbc5c !important;
  background: #f2ede2 !important;
}
.not-converted-style {
  border-radius: 1.375rem !important;
  border: 1px solid var(--Red-text, #ed5e5e) !important;
  background: #f5ebeb !important;
}

//

.Rules-div {
  & .Custom-input {
    width: auto;
  }
  & input {
    cursor: pointer;
  }
}

//
.h-20rem {
  height: 20rem !important;
}
.form-flex-container {
  & .Select-input {
    margin-right: unset;
    width: auto;
  }
}
.btn-secondary:focus {
  background: #753cb7;
  font-weight: 500;
}

.adduser {
  & .Select-input {
    width: 100%;
    margin: 0 !important;
  }
}

//

.discound-type {
  & .fixedwidth {
    & .Select-input {
      width: 12rem !important;
      margin-right: 0.5rem;
    }
  }
  & .fixedwidthtype {
    & .Select-input {
      width: 5rem !important;
      margin-right: 0.5rem;
    }
  }
}

// ====================================== Carousal ==============================================
.Carousal {
  & .addcarousalimagebox {
    width: 5rem;
    height: 5rem;
    border-radius: 1rem;
    display: flex;
    border-style: dashed;
    justify-content: center;
    align-items: center;
    font-size: xx-large;
    // border: 1px solid black;
  }
}

/*=============================== GENERAL SETTING ====================================*/

.generalsetting {
  height: 10rem;
  & .gauth {
    flex-direction: row !important;
    justify-content: space-between;
  }
}

.fixedHeightv2 {
  & .Select-input {
    width: 100% !important;
  }
}

.select-100 {
  .Select-input {
    width: 100% !important;
  }
}

.container2 {
  background-color: #fff;
  padding: 20px 40px;
  text-align: center;
}
.container2 span {
  display: block;
  font-size: 16px;
  color: #333;
}
.last-import {
  font-weight: bold;
  font-size: 24px;
  color: #007bff;
  margin-top: 10px;
}

.heading-style {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;

  color: #1e293b;
}

.PM-setting-region .Select-input {
  margin-right: 0 !important;
}
.tippy-content {
  background: #f2f2f2;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  height: 64px;
  display: flex;
  align-items: center;
  & .spot-option {
    display: flex;
    height: 100%;
    align-items: center;
  }
}

.hotspotproperty {
  & .form-body {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
}

.input-group-text {
  background: none;
  border-left: none;
}

.Table-heading {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #1e293b;
}
.pl-68 {
  padding-left: 56px !important;
}

.Innertable {
  &_name {
    color: #1e293b !important;
    font-weight: 600;
  }
  &_skucode {
    font-weight: 400;
  }
  & span {
    font-family: 'Inter';
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
  }
}
.display-statuss {
  & .span {
    color: #1e293b;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }
}

.Select-input-Select {
  & .Select-input {
    width: 10rem !important;
    margin-right: 0 !important;
  }
}

.ck-powered-by {
  display: none;
}
.device-height {
  min-height: calc(100dvh - 100px);
}
